import React from "react";
import BreadCumb from "../../../components/Breadcrumb";
import ProGeneralInfo from "../../../components/Ws/Provider/Settings/GeneralInfo";
import { useTranslation } from "react-i18next";

const ProSettings = () => {
  const { t } = useTranslation();
  return (
    <div>
      <BreadCumb path={t("settings")} />
      <ProGeneralInfo />
    </div>
  );
};

export default ProSettings;
