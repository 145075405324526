import React, { useEffect, useState } from "react";
import BreadCumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import axios from "axios";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VetsCMode = () => {
  const [vets, setVets] = useState();
  const [sort, setSort] = useState("az");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [cities, setCities] = useState();
  const [districts, setDistricts] = useState();
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQuery();

  const searchQuery = query.get("search") || "";
  const cityQuery = query.get("city") || "";
  const districtQuery = query.get("district") || "";

  useEffect(() => {
    const getVets = async () => {
      try {
        const API_URL =
          process.env.REACT_APP_NODE_ENV === "production"
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_LOCAL_API_URL;

        const response = await axios.get(`${API_URL}vet/page/${currentPage}`, {
          params: {
            search: searchQuery,
            city: cityQuery,
            district: districtQuery,
            sort: sort,
          },
        });
        if (response.data) {
          setVets(response.data.vets);
          setTotalPages(response.data.total);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getVets();
  }, [searchQuery, cityQuery, districtQuery, sort, currentPage]);

  useEffect(() => {
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const getCities = async () => {
      const response = await axios.get(`${API_URL}locations/cities`);
      setCities(response.data);
    };
    getCities();
  }, []);

  useEffect(() => {
    if (cityQuery) {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const getDistricts = async () => {
        const response = await axios.get(
          `${API_URL}locations/districts/${cityQuery}`
        );
        setDistricts(response.data);
      };
      getDistricts();
    }
  }, [cityQuery]);

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    history.push(`/vets?search=${searchQuery}&city=${selectedCity}`);
  };

  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    history.push(
      `/vets?search=${searchQuery}&city=${cityQuery}&district=${selectedDistrict}`
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const searchValue = e.currentTarget.search.value;

    history.push(`/vets?search=${searchValue}`);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <BreadCumb path={t("vets")} />
      <div className="shop-area pt-60 pb-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-8 order-2 order-lg-0">
              <aside className="shop-sidebar">
                <div className="widget">
                  <h4 className="sidebar-title">{t("province")}</h4>
                  <div>
                    <select
                      style={{
                        padding: "8px",
                        backgroundColor: "#637579",
                        borderRadius: "5px",
                        marginBlock: "5px",
                      }}
                      id="city"
                      className="form-select btn"
                      onChange={handleCityChange}
                      value={cityQuery}
                      aria-label="Default select example"
                    >
                      <option
                        style={{ backgroundColor: "#0a313b" }}
                        value=""
                        disabled
                      >
                        {t("select")}
                      </option>
                      {cities &&
                        cities.map((city) => (
                          <option
                            style={{ backgroundColor: "#0a313b" }}
                            key={city.sehir_adi}
                            value={city.id}
                          >
                            {city.sehir_adi}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {query.get("city") && (
                  <div className="widget">
                    <h4 className="sidebar-title">{t("district")}</h4>
                    <div>
                      <select
                        style={{
                          padding: "8px",
                          backgroundColor: "#637579",
                          borderRadius: "5px",
                          marginBlock: "5px",
                        }}
                        id="district"
                        className="form-select btn"
                        onChange={handleDistrictChange}
                        value={districtQuery}
                        aria-label="Default select example"
                      >
                        <option
                          style={{ backgroundColor: "#0a313b" }}
                          value=""
                          disabled
                        >
                          {t("district")}
                        </option>
                        {districts &&
                          districts.map((district) => (
                            <option
                              style={{ backgroundColor: "#0a313b" }}
                              key={district.ilce_adi}
                              value={district.id}
                            >
                              {district.ilce_adi}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                )}
              </aside>
            </div>
            <div className="col-lg-9">
              <div className="shop-wrap">
                <div className="search-form mb-10">
                  <form onSubmit={handleSearch}>
                    <input
                      id="search"
                      type="text"
                      name="search"
                      placeholder={t("Search_Here")}
                      defaultValue={searchQuery}
                    />
                    <button className="search-btn">
                      <i className="fas fa-search" />
                    </button>
                  </form>
                </div>
                <div className="shop-page-meta mb-30">
                  <div className="shop-showing-result">
                    <p>
                      {t("total_items")}: {totalPages && totalPages}
                    </p>
                  </div>
                  <div className="shop-short-by">
                    <form>
                      <label htmlFor="shortBy">{t("sort_by")}</label>
                      <select
                        onChange={(value) => {
                          setSort(value.target.value);
                        }}
                        id="shortBy"
                        className="selected"
                      >
                        <option value="az">{t("A-Z")}</option>
                        <option value="za">{t("Z-A")}</option>
                      </select>
                    </form>
                  </div>
                </div>
                <div className="row justify-content-center">
                  {vets &&
                    vets.map((vet) => (
                      <div key={vet._id} className="col-lg-4 col-sm-6">
                        <div className="shop-item mb-55">
                          <div className="shop-thumb">
                            <Link to={`/vets/${vet._id}`}>
                              <img
                                src={vet.image || "img/pet/default.png"}
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="shop-content">
                            <span>{t("Veteriner")}</span>
                            <h4 className="title">
                              <Link to={`/vets/${vet._id}`}>{vet.title}</Link>
                            </h4>
                            <div
                              style={{ justifyContent: "flex-end" }}
                              className="shop-content-bottom"
                            >
                              <span className="add-cart">
                                <Link to={`/vets/${vet._id}`}>Detaya Git</Link>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="shop-page-meta justify-content-between p-2">
                  <div>
                    {t("total_Items_1_6_of")} {totalPages}
                  </div>
                  <div>
                    {Array.from(
                      { length: Math.ceil(totalPages / 15) },
                      (_, index) => (
                        <button
                          key={index + 1}
                          style={{
                            width: 35,
                            height: 35,
                            marginInline: 5,
                            border: "1px solid #e1e1e1",
                            borderRadius: "3px",
                            fontSize: "13px",
                            color:
                              currentPage === index + 1 ? "white" : "black",
                            fontWeight: "500",
                            background:
                              currentPage === index + 1 ? "red" : "white",
                            cursor: "pointer",
                          }}
                          onMouseOver={(e) =>
                            (e.target.style.background =
                              currentPage === index + 1 ? "red" : "#f0f0f0")
                          }
                          onMouseOut={(e) =>
                            (e.target.style.background =
                              currentPage === index + 1 ? "red" : "white")
                          }
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </button>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VetsCMode;
