import React from "react";
import BreadCumb from "../../../components/Breadcrumb";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const PetshopInformation = () => {
  const { t, i18n } = useTranslation();
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const user = JSON.parse(localStorage.getItem("pet_user"));

  const handlePetshopName = async (e) => {
    e.preventDefault();

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const payload = {
        name: e.currentTarget.fullname.value,
      };

      const res = await axios.post(`${API_URL}petshops`, payload, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      if (res.data) {
        localStorage.setItem("pet_user", JSON.stringify(await res.data));
        Swal.fire({
          icon: "success",
          title: "Success",
          text: t("Your information has been successfully saved"),
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };

  return (
    <div>
      <BreadCumb path={t("petshop_informations")} />
      <div className="d-flex my-5 justify-content-center align-items-center">
        <form
          onSubmit={handlePetshopName}
          className="contact-form"
          style={{ width: "23rem" }}
        >
          <div className="form-grp mb-4">
            <label className="form-label" htmlFor="fullname">
              {t("petshop_name")}
            </label>
            <input
              required
              type="text"
              id="fullname"
              defaultValue={user?.petshop?.name}
              className="form-control"
            />
          </div>
          <div className="pt-1 my-5">
            <button
              className="btn btn-info btn-lg btn-block d-flex justify-content-center"
              type="submit"
            >
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PetshopInformation;
